import * as React from 'react'
import Helmet from 'react-helmet'
import TopBar from '../components/top-bar'
import Header from './header'
import Footer from '../components/footer'

const Layout = ({ pageTitle, children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <TopBar />
      <Header />
      <main className="flex-1">
        <article className="relative py-16 bg-white overflow-hidden">
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
              {children}
            </div>
          </div>
        </article>
      </main>
      <Footer />
    </div>
  )
}

export default Layout
