import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/article-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Veterans welcomed in Williams for Arizona elk hunt`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "670px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b87b49cf8754b9b6246a727a4145f480/63a7e/veterans-1.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.50000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwABAv/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAeEFBbI//8QAGBAAAwEBAAAAAAAAAAAAAAAAAAECERL/2gAIAQEAAQUC1nVHTHOnLJnF/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAS/9oACAEDAQE/AUsF/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQASUf/aAAgBAgEBPwEbb2//xAAZEAACAwEAAAAAAAAAAAAAAAAAMQEQEUH/2gAIAQEABj8CdKBnB6f/xAAcEAACAgIDAAAAAAAAAAAAAAABEQAhEDFRYXH/2gAIAQEAAT8hIwyqpwVavVYwvFA0F06hlH0n/9oADAMBAAIAAwAAABD07//EABcRAQEBAQAAAAAAAAAAAAAAAAEAMVH/2gAIAQMBAT8QF0jhf//EABYRAQEBAAAAAAAAAAAAAAAAAAEQMf/aAAgBAgEBPxBTGL//xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMUFh/9oACAEBAAE/EMGyhWCJ9tMqNf7pcswoVQ6D41KdY8sO3K+uuHZ//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "veterans 1",
            "title": "veterans 1",
            "src": "/static/b87b49cf8754b9b6246a727a4145f480/63a7e/veterans-1.jpg",
            "srcSet": ["/static/b87b49cf8754b9b6246a727a4145f480/e07e9/veterans-1.jpg 200w", "/static/b87b49cf8754b9b6246a727a4145f480/066f9/veterans-1.jpg 400w", "/static/b87b49cf8754b9b6246a727a4145f480/63a7e/veterans-1.jpg 670w"],
            "sizes": "(max-width: 670px) 100vw, 670px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`William’s Sportsman’s Club hosted its second group of U.S. military veterans in December for an elk hunt sponsored by Combat Warriors Inc., a 501c3 non-profit foundation based out of North Carolina.`}</p>
    <p>{`Combat Warriors’ mission is to honor and support, emotionally and financially, the commitment and courage of combat veterans by providing quality outdoor experiences. Volunteers organize and promote dozens of events annually for groups of active duty and retired servicemen for hunting and fishing trips at locales offered by the group’s sponsors.
Mike Tierney, president of the group’s Northern Arizona Chapter and owner of Cow Hill Outfitters in Williams, donated a week-long elk hunting trip in support of this effort. Mike also set up a base camp off Spring Valley Road and coordinated with Keith Heimes, president of the William’s Sportsman’s Club, for a visit to the Williams Shooting Range to sight in rifles.`}</p>
    <p>{`“It was an honor to provide the base camp and help with guide services. I’m looking forward to hosting another group of military personnel next year,” Tierney said.`}</p>
    <p>{`Combat Warriors founder Bill Warren flew in from North Carolina to join the group, which consisted of five veterans and five event organizers. Warren acknowledged Tierney and Heimes’ efforts as indispensable and said they were the key to a successful week.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "670px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/77720a705d66635e4214cbf0e024accd/63a7e/veterans-2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAFEpGNxoF//xAAbEAABBQEBAAAAAAAAAAAAAAABAAIDERITM//aAAgBAQABBQLAyJKPa07yhNOMTF//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAdEAABAwUBAAAAAAAAAAAAAAAAAREhAhASMUGh/9oACAEBAAY/AsuqQ9JK+WY0f//EAB0QAAIBBAMAAAAAAAAAAAAAAAABIRExUWFxsfD/2gAIAQEAAT8hdiCWdGhPNnvJ0IZRWqQM1ln/2gAMAwEAAgADAAAAEK/v/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEhEf/aAAgBAwEBPxCuGI//xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8QgX//xAAeEAEAAwABBQEAAAAAAAAAAAABABExIVFhcYGRsf/aAAgBAQABPxBFORXUrm6yGyBXBEKRqryCWb1r5/UzNHaDFpUeNHv6l1aBa0+z/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "veterans 2",
            "title": "veterans 2",
            "src": "/static/77720a705d66635e4214cbf0e024accd/63a7e/veterans-2.jpg",
            "srcSet": ["/static/77720a705d66635e4214cbf0e024accd/e07e9/veterans-2.jpg 200w", "/static/77720a705d66635e4214cbf0e024accd/066f9/veterans-2.jpg 400w", "/static/77720a705d66635e4214cbf0e024accd/63a7e/veterans-2.jpg 670w"],
            "sizes": "(max-width: 670px) 100vw, 670px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`According to Warren, while the majority of the group’s funding resources are focused on outings for veterans, the group also reaches out to financially assist soldiers in times of need and have helped widows and their children get back on their feet when warriors are lost in battle.`}</p>
    <p>{`Many of these veterans are active duty personnel serving in special operations units and shun the spotlight for security reasons.`}</p>
    <p>{`Heimes said he was happy to assist the group and introduce more visitors to Williams and the shooting range.`}</p>
    <p>{`“That’s one of the founding reasons the shooting range was created by the city — to entice visitation while also providing a resource to visiting hunters and an ongoing shooting sports venue to the residents of Williams and its outlying neighbors,” he said.`}</p>
    <p>{`More information about Williams Sportsman’s Club is available from Bruce Speirs at `}<a parentName="p" {...{
        "href": "mailto:bspeirs1055@gmail.com"
      }}>{`bspeirs1055@gmail.com`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      